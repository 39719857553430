var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"checkout-wrapper",class:{ 'ja' : _vm.$i18n.locale == 'ja' }},[_c('cookies-popup'),_c('div',{staticClass:"checkout-nav"},[_c('div',{staticClass:"left-side"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}})],1)]),_c('h2',[_vm._v(_vm._s(_vm.$t("checkout.title")))]),_c('p',{staticClass:"checkout-sub-title"},[_vm._v(_vm._s(_vm.$t("checkout.subtitle")))]),_c('div',{staticClass:"checkout-inner"},[_c('div',{staticClass:"package-slider-wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.$t("checkout.viewsTitle2")))]),_c('div',{staticClass:"slider-block-wrapper"},[(_vm.viewsAmounts.length)?_c('vue-slider',{staticClass:"bootstrap-slider",attrs:{"data":_vm.options,"width":"auto","tooltip":_vm.tooltip,"railStyle":{
                        'background-color': 'rgba(230, 204, 183, 0.4)'
                    },"processStyle":{
                        'background-color': '#fab131'
                    },"dotStyle":{
                        'position': 'relative',
                        'width': '44px',
                        'height': '44px',
                        'top': '-16px',
                        'left': '-14px',
                        'background': '#F5F6FC',
                        'border': '5px solid #fab131',
                        'webkit-box-shadow':'none',
                        'box-shadow': 'none'
                    }},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',{staticClass:"slider-tooltip-custom"},[_vm._v(_vm._s(_vm.getCheckoutPackage.viewsAmountLocal))])]},proxy:true}],null,false,1731505593),model:{value:(_vm.packageSlug),callback:function ($$v) {_vm.packageSlug=$$v},expression:"packageSlug"}}):_vm._e(),(_vm.viewsAmounts.length)?_c('div',{staticClass:"lines-wrapper"},[_c('div',{staticClass:"left-line-first"},[_vm._v(_vm._s(_vm.viewsAmounts[0].toLocaleString('ru-RU')))]),_c('div',{staticClass:"left-line-middle"},[_vm._v(_vm._s(_vm.viewsAmounts[1].toLocaleString('ru-RU')))]),_c('div',{staticClass:"left-line-last"},[_vm._v(_vm._s(_vm.viewsAmounts[2].toLocaleString('ru-RU')))])]):_vm._e()],1)]),_c('checkout-form'),_c('div',{staticClass:"cart"},[_c('div',{staticClass:"packages"},[_c('package-special')],1)])],1),_c('footerSection')],1)}
var staticRenderFns = []

export { render, staticRenderFns }